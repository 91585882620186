@import 'variables';

.custom-pgi-card {
    display: inline-block;
    max-width: 32%;
    margin-right: .5%;
    margin-left: .5%;
    text-align: center;
}

.text-center {
    text-align: center;
}

.card {
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.1);
    border: 1px solid rgba(0, 0, 0, 0.25);

    .card {
        box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.05);
        -webkit-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.05);
        -moz-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.05);
        border: 1px solid rgba(0, 0, 0, 0.125);
    }
}

.btn-primary {
    background: $primary-blue-1;
    color: white;

    &:hover, &:focus {
        background: $primary-blue-2;
    }
}

.btn-info {
    background: #00A29B;

    &:hover, &:focus {
        background: #03D3AE;
    }
}

.custom-pgi-large-card, .custom-pgi-report-record-card {
    display: inline-block;
    max-width: 100%;
    padding: 0 40px;
    text-align: center;

    h5 {
        color: $primary-blue-2;
        font-weight: 700;
        font-size: 24px;
    }

    h6 {
        color: #333;
        font-weight: 600;
    }

    .custom-pgi-loading-spinner {
        margin: 12px 0;
    }

    .custom-pgi-client-selector-container {
        margin: 20px 0 0;
        padding: 0 80px;
        .client-select {
            text-align: left !important;
        }
    }

    .custom-pgi-entity-table {
        margin: 12px 0;
        border: 1px solid #000;

        thead {
            tr:first-child {
                th {
                    border-top: 1px solid #000;
                    border-bottom: 1px solid #333;
                }
            }
        }

        tbody {
            tr {
                th {
                    font-weight: 400;
                }
            }
        }
    }
}

a {
    border-bottom: none;

    &:hover, &:focus {
        border-bottom: none;
    }
}

.custom-pgi-centered-container {
    text-align: center;
}

.custom-pgi-loading-spinner {
    img {
        max-width: 30%;
        max-height: 30%;
    }
}

.navbar {
    .navbar-brand {
        color: white;
        transition: 0.3s ease all;

        &:hover, &:focus {
            text-decoration: underline;
            color: darken(white, 20%);
        }
    }

    .dropdown {
        .dropdown-toggle {
            background: white;
            color: $primary-blue-2;
            border-color: $primary-blue-2;

            &:hover, &:focus {
                text-decoration: underline;
                background: darken(white, 10%);
            }

            &:not(:disabled):not(.disabled):active {
                background: white;
                color: $primary-blue-2;
            }
        }

        &.show {
            .dropdown-toggle {
                background: $primary-blue-2;
                color: white;
                box-shadow: none;
                &:hover {
                    text-decoration: underline;
                    background: darken($primary-blue-2, 10%);
                }
            }
        }

        .dropdown-menu {
            left: auto !important;
            right: 0;
            border: 1px solid $primary-blue-1;
            box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.15);
            -webkit-box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.15);
            -moz-box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.15);

            .nav-link {
                color: $primary-blue-2;
                transition: 0.3s ease all;
                &:hover, &:focus {
                    color: $primary-blue-1;
                    text-decoration: underline;
                }
            }
        }
    }
}

.custom-pgi-home-cards {
    text-align: center;

    .card {
        min-height: 150px;
        min-width: 400px;

        .card-title {
            margin: 0 0 24px;
        }
    }
}