@import 'variables';

.custom-pgi-report-listing-info {
    .btn-info {
        margin: 12px 12px;
    }
}

.custom-pgi-reports-listing {
    .custom-pgi-report-record-card {
        display: inline-flex;

        .btn {
            margin: 12px 6px;

            &.btn-primary {
                background: $primary-blue-1;
                color: white;

                &:hover, &:focus {
                    background: $primary-blue-2;
                }
            }
        }

        .btn-delete {
            padding: 3px 12px 6px;
        }
    }
}
