@import 'variables';

.custom-pgi-report-results-container {
    margin-top: 5%;
}

.custom-pgi-report-record-card {
    margin: 1%;
    display: inline-flex;

    table {
        font-size: 12px;
    }
}
