Provide sufficient contrast against white background a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.custom-pgi-loading-spinner .center {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
}

.center-align-logout-msg {
  position: absolute;
  top: 15%;
  left: 40%;
  margin-top: -50px;
  width: auto;
  height: auto;
}

.center-align-button {
  position: absolute;
  top: 25%;
  left: 45%;
  margin-top: -50px;
  margin-left: -50px;
  width: auto;
  height: auto;
}